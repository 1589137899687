import React from "react";
import MetaTags from "react-meta-tags";

function NoMatch() {
	return (
		<React.Fragment>
			<MetaTags>
				<title>Catopia - 404</title>
				<meta name="description" content="Error 404: File not found." />
				<meta property="og:title" content="Catopia - 404" />
			</MetaTags>
			<h1>Error 404: File not found.</h1>
		</React.Fragment>
	);
}

export default NoMatch;
