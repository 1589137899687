import React from "react";
import MetaTags from "react-meta-tags";

function Cats() {
	document.title = "Catopia - Cat Humor";

	return (
		<React.Fragment>
			<MetaTags>
				<title>Catopia - Cat Humor</title>
				<meta name="description" content="Click on a cat in the navigation bar to begin watching!" />
				<meta
					property="og:title"
					content="Catopia - Cat Humor"
				/>
			</MetaTags>
			<p>Click on a cat in the navigation bar to begin watching!</p>
		</React.Fragment>
	);
}

export default Cats;
