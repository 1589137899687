import React from "react";
import MetaTags from "react-meta-tags";

function UnderConstruction(props) {
	document.title = "Catopia - Under Construction";

	return (
		<React.Fragment>
			<MetaTags>
				<title>Catopia - Under Construction</title>
				<meta
					name="description"
					content="This page is still being built, please come back later!"
				/>
				<meta
					property="og:title"
					content="Catopia - Under Construction"
				/>
			</MetaTags>
			<h1>Oops!</h1>
			<p>This page is still being built, please come back later!</p>
		</React.Fragment>
	);
}

export default UnderConstruction;
