import React from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
import catdata from "../catdata.json";
import { normalizeTitle } from "../utilities";
import "./CatPage.scss";

import NoMatch from "./NoMatch.js";

function CatPage() {
	const { id } = useParams();

	let selectedCat;
	for (let cat of catdata) {
		if (id == normalizeTitle(cat.short_title)) {
			selectedCat = cat;
		}
	}

	return (
		<React.Fragment>
			<MetaTags>
				<title>Catopia - {selectedCat.short_title}</title>
				<meta name="description" content={selectedCat.description} />
				<meta
					property="og:title"
					content={"Catopia - " + selectedCat.short_title}
				/>
			</MetaTags>
			{!!selectedCat ? (
				<React.Fragment>
					<h2
						dangerouslySetInnerHTML={{
							__html: selectedCat.long_title,
						}}
					></h2>
					<p
						className="cat-paragraph"
						dangerouslySetInnerHTML={{
							__html: selectedCat.description,
						}}
					></p>
					<div className="cat-media">
						<iframe
							width="560"
							height="315"
							src={
								"https://www.youtube-nocookie.com/embed/" +
								selectedCat.video_id +
								"?autoplay=1"
							}
							frameBorder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
						{selectedCat.meme_links
							? selectedCat.meme_links.map((meme_link, index) => {
									return (
										<img
											key={"catmeme-" + index}
											src={meme_link}
										/>
									);
							  })
							: ""}
					</div>
				</React.Fragment>
			) : (
				<NoMatch />
			)}
		</React.Fragment>
	);
}

export default CatPage;
