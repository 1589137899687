import React from "react";
import { NavLink } from "react-router-dom";
import catdata from "../catdata.json";
import { normalizeTitle } from "../utilities";

function CatsNavigation(props) {
	return (
		<div className="sub-menu">
			{catdata.map((value, index) => {
				return (
					<NavLink
						key={"catlink-" + index}
						to={"/cathumor/" + normalizeTitle(value.short_title)}
						activeClassName="selected"
					>
						{value.short_title}
					</NavLink>
				);
			})}
		</div>
	);
}

export default CatsNavigation;
