import React from "react";
import MetaTags from "react-meta-tags";
import "./Home.scss";

function Home(props) {
	document.title = "Catopia";

	return (
		<React.Fragment>
			<MetaTags>
				<title>Catopia</title>
				<meta
					name="description"
					content="I present to you my accumulation of cat humor. This site seeks to entertain cat lovers everywhere and inform future cat owners of the joys and pitfalls of cat ownership. Additionally, I will provide an overview of the most common breeds of cats and their attributes to assist in the selection of the optimum cat for your household."
				/>
				<meta property="og:title" content="Catopia" />
			</MetaTags>
			<h1>Catopia</h1>
			<p>
				I present to you my accumulation of cat humor. This site seeks
				to entertain cat lovers everywhere and inform future cat owners
				of the joys and pitfalls of cat ownership. Additionally, I will
				provide an overview of the most common breeds of cats and their
				attributes to assist in the selection of the optimum cat for
				your household.
			</p>
			<img
				className="bannerImage"
				src="https://source.unsplash.com/random/1920x1080?cats"
			/>
		</React.Fragment>
	);
}

export default Home;
