import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import "reset-css";

import Home from "./pages/Home.js";
import Cats from "./pages/Cats.js";
import CatPage from "./pages/CatPage.js";
import NoMatch from "./pages/NoMatch.js";
import UnderConstruction from "./pages/UnderConstruction.js";

import Navigation from "./components/Navigation.js";

function App() {
	return (
		<Router>
			<Navigation />
			<div id="page">
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route exact path="/cathumor">
						<Cats />
					</Route>
					<Route exact path="/catbreeds">
						<UnderConstruction />
					</Route>
					<Route path="/cathumor/:id">
						<CatPage />
					</Route>
					<Route path="*">
						<NoMatch />
					</Route>
				</Switch>
			</div>
		</Router>
	);
}

export default App;
