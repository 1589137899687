import React from "react";
import { Route, NavLink } from "react-router-dom";
import "./Navigation.scss";
import catdata from "../catdata.json";
import { normalizeTitle } from "../utilities";

import CatsNavigation from "./CatsNavigation.js";

function Navigation(props) {
	return (
		<nav id="navigation">
			<NavLink exact to="/" activeClassName="selected">
				Home
			</NavLink>
			<NavLink to={"/cathumor"} activeClassName="selected">
				Cat Humor
			</NavLink>
			<NavLink to={"/catbreeds"} activeClassName="selected">
				Cat Breeds
			</NavLink>
			<Route path="/cathumor">
				<CatsNavigation />
			</Route>
		</nav>
	);
}

export default Navigation;
